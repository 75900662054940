<script>
import appConfig from "@/app.config";
import MarketingSurveyAreasTeam from "./components/MarketingSurveyAreasTeam.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    MarketingSurveyAreasTeam,
  },
    data() {
          return {
            survey:{},
            area:{}
          }
        },
        methods: {
                getSurvey() {
            this.http.get("marketing-surveys/"+this.$route.params.surveyId).then((res) => {
              this.survey = res.data;
            });
          },
          getArea(){
            this.http.get('marketing-areas/'+this.$route.params.areaId).then((res)=>{
              if(res.status){
                this.area= res.data
                console.log("step 33 :",this.area);
                
              }
            })
          }
        },
        mounted() {
          this.getSurvey()
          this.getArea( )
        },
};
</script>

<template>
  <div class="row">
    <div class="row align-items-center">
      <div class="d-flex align-items-center mb-4">
        <router-link :to="`/dashboard/marketing_survey`">
          <h4 class="m-0">{{ $t("menu.menuitems.marketing.tabs.survey_list") }}</h4>
        </router-link>
        <div class="mx-2">\</div>
        <router-link :to="{ name: 'marketing_survey_details', params: { id: this.$route.params?.surveyId } }">
          <div>{{  survey && survey.id ? survey.name: $t("menu.menuitems.marketing.survey_details.text") }}</div>
        </router-link>
        <div class="mx-2">\</div>
        <div>{{ $t("menu.menuitems.marketing.tabs.area_team_members") }}</div>
        <div class="mx-2">\</div>
        <div>{{ this.area?.name }}</div>
      </div>
    </div>
  </div>
  <marketing-survey-areas-team></marketing-survey-areas-team>
</template>
